import { gql, useMutation, useQuery } from '@apollo/client';
import { Form, Input, Modal, Result, Select, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import {
  SearchTaskResultsQuery,
  SearchTaskResultsQueryVariables,
  ClientFortisiemDetailsQuery,
  TechnologySourceType,
} from 'types/graph-codegen/graph-types';
import { TaskStatusBadge } from 'components/admin/task-handler/task-log-detail';
import { IClient } from 'types';
import { NuButton, Spacer } from 'components/nuspire';
import { ScheduleTaskModal } from '../schedules/schedule-task-modal';
import { useState } from 'react';
import { GraphQLFormattedError } from 'graphql';
import styled from 'styled-components';
import baseTheme from 'components/theme';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { DeleteOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const CLIENT_FORTISIEM_DETAILS = gql`
  query ClientFortisiemDetails(
    $size: Int
    $status: String
    $clientId: String!
    $executableSlug: String
    $scheduleId: String
  ) {
    searchTaskResults(
      size: $size
      status: $status
      clientId: $clientId
      executableSlug: $executableSlug
      scheduleId: $scheduleId
    ) {
      items {
        id
        taskStatus
        executableSlug
        schedule {
          id
          cron
        }
        clientId
        client {
          id
          name
        }
        scheduledTime
        startedAt
        completedAt
        ok
        summary
        error
        scheduleId
        data
      }
    }

    technologySources(clientId: $clientId) {
      id
      clientId
      technologyId
      type
      timeToLive
      technologyParser {
        id
        name
      }
    }

    technologies {
      id
      name
      technologyId
    }
  }
`;

const PUT_TECHNOLOGY_SOURCE = gql`
  mutation PutTechnologySources($input: CreateTechnologySourceInput!) {
    putTechnologySource(input: $input) {
      id
      clientId
      technologyId
      type
      timeToLive
    }
  }
`;

const DELETE_TECHNOLOGY_SOURCE = gql`
  mutation DeleteTechnologySource($clientId: String!, $technologyId: String!, $type: TechnologySourceType) {
    deleteTechnologySource(clientId: $clientId, technologyId: $technologyId, type: $type)
  }
`;

export function ClientFortisiemDetails(props: { client: IClient }) {
  const { client } = props;
  const { data, loading } = useQuery<SearchTaskResultsQuery, SearchTaskResultsQueryVariables>(
    CLIENT_FORTISIEM_DETAILS,
    {
      variables: { clientId: client.id, executableSlug: 'fortisiem-client-task', size: 20 },
    },
  );

  return (
    <>
      <FortisiemTaskHandlerDetails client={client} data={data} loading={loading} />
      <Spacer />
      <FortisiemTechnologySources data={data} client={client} loading={loading} />
    </>
  );
}

function FortisiemTaskHandlerDetails(props: { client: IClient; loading: boolean; data?: ClientFortisiemDetailsQuery }) {
  const { client, loading, data } = props;
  const [isScheduleTaskOpen, setIsScheduleTaskOpen] = useState<boolean>(false);

  return (
    <>
      <Typography.Title level={3}>Task Results</Typography.Title>
      <NuButton
        type="primary"
        onClick={() => {
          setIsScheduleTaskOpen(true);
        }}
      >
        Run Now For {client.name}
      </NuButton>

      <Spacer />

      <ScheduleTaskModal
        taskSlug="fortisiem-all-clients-task"
        open={isScheduleTaskOpen}
        onCancel={() => setIsScheduleTaskOpen(false)}
        showTitle={false}
        initialValues={{
          clientList: client.id,
        }}
      />
      <Table
        dataSource={data?.searchTaskResults?.items}
        loading={loading}
        rowKey="id"
        pagination={false}
        columns={[
          {
            key: 'startedAt',
            title: 'Started At',
            dataIndex: 'startedAt',
            render(startedAt) {
              return <Tooltip overlay={startedAt}>{dayjs(startedAt).fromNow()}</Tooltip>;
            },
          },
          {
            key: 'taskStatus',
            width: 100,
            title: 'Status',
            dataIndex: 'taskStatus',
            render: (taskStatus) => <TaskStatusBadge status={taskStatus} />,
          },
          {
            key: 'summary',
            title: 'Summary',
            dataIndex: 'summary',
          },
        ]}
        expandable={{
          expandedRowRender: (record) => {
            const data = record.data as {
              [key in string]: { success: boolean; message: string; hasMissingQueryResults?: boolean };
            };

            return Object.entries(data).map(([key, results]) => (
              <div style={{ marginBottom: '1rem' }}>
                <Typography.Title level={4}>{key}</Typography.Title>
                {Object.entries(results).map(([resultKey, value]) => (
                  <div>
                    <Typography.Text>
                      <strong>{resultKey}: </strong>
                      {value.toString()}
                    </Typography.Text>
                  </div>
                ))}
              </div>
            ));
          },
          rowExpandable: (record) => !!record?.data,
        }}
      />
    </>
  );
}

function FortisiemTechnologySources(props: { client: IClient; loading: boolean; data?: ClientFortisiemDetailsQuery }) {
  const { client, loading, data } = props;
  const [isCreateTechnologySourceOpen, setIsCreateTechnologySourceOpen] = useState<boolean>(false);
  const [deleteTechnologySource, { loading: deleting }] = useMutation(DELETE_TECHNOLOGY_SOURCE, {
    refetchQueries: ['ClientFortisiemDetails'],
  });

  return (
    <>
      <Typography.Title level={3}>Technology Sources</Typography.Title>
      <NuButton
        type="primary"
        onClick={() => {
          setIsCreateTechnologySourceOpen(true);
        }}
      >
        Create Technology Source
      </NuButton>

      <Spacer />

      <PutTechnologySourceModal
        open={isCreateTechnologySourceOpen}
        close={() => setIsCreateTechnologySourceOpen(false)}
        data={data}
        clientId={client.id}
      />
      <Table
        dataSource={data?.technologySources}
        loading={loading || deleting}
        rowKey="id"
        pagination={false}
        columns={[
          {
            key: 'technologyParser',
            title: 'Technology',
            dataIndex: 'technologyParser',
            render(technologyParser, record) {
              return technologyParser?.name ?? record.technologyId;
            },
          },
          {
            key: 'type',
            title: 'Type',
            dataIndex: 'type',
          },
          {
            key: 'timeToLive',
            title: 'TTL',
            dataIndex: 'timeToLive',
            render: (ttl) => {
              if (ttl) {
                return <p>{dayjs(ttl * 1000).format('MMM DD, YYYY')}</p>;
              } else {
                return <p>Never</p>;
              }
            },
          },
          {
            title: 'Actions',
            key: 'id',
            dataIndex: 'id',
            align: 'center' as any,
            render: (_, record) => {
              return (
                <DeleteOutlined
                  onClick={() =>
                    confirm({
                      title: 'Do you want to remove this item?',
                      onOk() {
                        deleteTechnologySource({
                          variables: { clientId: client.id, technologyId: record.technologyId, type: record?.type },
                        });
                      },
                    })
                  }
                />
              );
            },
          },
        ]}
      />
    </>
  );
}

const RetryLink = styled(Typography.Text)`
  display: block;
  text-align: center;
  color: ${baseTheme.color.nuspireBlue};
  margin-top: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

function PutTechnologySourceModal(props: {
  clientId: string;
  data?: ClientFortisiemDetailsQuery;
  open: boolean;
  close: () => void;
}) {
  const { data, open, close, clientId } = props;

  const [putTechnologySource, { loading }] = useMutation(PUT_TECHNOLOGY_SOURCE, {
    refetchQueries: ['ClientFortisiemDetails'],
  });
  const [form] = Form.useForm();

  const [showStatus, setShowStatus] = useState<{
    show: boolean;
    status?: 'error' | 'success';
    errors?: GraphQLFormattedError[];
  }>({
    show: false,
  });

  const onFinish = async (values) => {
    const input = {
      ...values,
      clientId,
      ignoreTTL: !values?.timeToLive ? true : false,
      timeToLive: values?.timeToLive ? Number(values.timeToLive) : undefined,
    };

    try {
      const { errors } = await putTechnologySource({
        variables: {
          input,
        },
      });

      if (errors?.length) {
        console.log({ errors });
        setShowStatus({
          show: true,
          status: 'error',
          errors: [...errors],
        });
      } else {
        form.resetFields();
        setShowStatus({
          show: true,
          status: 'success',
        });
      }
    } catch (error) {
      setShowStatus({
        show: true,
        status: 'error',
        errors: [error],
      });
    }
  };

  return (
    <Modal
      title="Create Technology Source"
      okText="Create"
      open={open}
      footer={false}
      onCancel={() => {
        close();
        setShowStatus({
          show: false,
        });
      }}
    >
      {loading && (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      )}

      {!loading && !showStatus.show ? (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="technologyId" label="Technology">
            <Select>
              {data?.technologies?.map((technology) => (
                <Select.Option
                  value={technology.technologyId}
                  key={technology.id}
                >{`${technology.name}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Select>
              {Object.entries(TechnologySourceType).map(([label, value]) => (
                <Select.Option value={value} key={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="timeToLive" label="Time To Live (days)">
            <Input type="number" />
          </Form.Item>
          <NuButton type="primary" htmlType="submit" loading={loading}>
            Create
          </NuButton>
        </Form>
      ) : null}

      {showStatus.show ? (
        <Result status={showStatus?.status}>
          {showStatus.status === 'success' ? (
            <>
              <Typography.Text>Technology Source created successfully</Typography.Text>
            </>
          ) : (
            <>
              <Typography.Text>Failed to create technology source. Errors:</Typography.Text>
              <ul>{showStatus.errors?.map((error) => <li>{error.message}</li>)}</ul>
              <RetryLink
                onClick={() => {
                  setShowStatus({ show: false });
                }}
              >
                Retry Request
              </RetryLink>
            </>
          )}
        </Result>
      ) : null}
    </Modal>
  );
}
