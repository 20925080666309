import {
  AreaChartOutlined,
  CloseOutlined,
  CloudServerOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Dropdown, Empty, Form, Input, message, Modal, Space, type MenuProps } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useClientContext } from 'components/client-context-provider';
import { DashboardsList } from 'components/dashboard/dashboards-modal';
import { EmptyState, NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import * as NuIcons from 'components/nuspire/nu-icon';
import baseTheme from 'components/theme';
import { WidgetContents } from 'components/widgets/widget/widget';
import { WIDGET_COMPONENTS_MAP } from 'components/widgets/widget/widget-types';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  SummaryWidgetDefinitionDataQuery,
  type SummaryWidgetDefinitionDataQueryVariables,
} from '../../types/graph-codegen/graph-types';
import { useAuthContext } from '../auth-context';
import { createDebugRequestId, useDebugRequests } from '../debug';
import { DataExplorerFilters, QueryDataTypeFilter } from './data-explorer-filters';
import { IDataType } from './data-explorer-results';

type IDetailWidget = { __typename?: 'DetailWidget'; id: string; name: string; type: string };

const DetailWidgetLiRoot = styled.div`
  margin-bottom: 18px;
`;

const QUERY_DETAIL_WIDGET = gql`
  query QueryDetailWidget($item: JSONObject!, $clientId: String!, $dataTypeSlug: String!, $detailWidgetSlug: String!) {
    dataTypeDetailWidget(
      item: $item
      clientId: $clientId
      dataTypeSlug: $dataTypeSlug
      detailWidgetSlug: $detailWidgetSlug
    ) {
      data
      configuration
    }
  }
`;

function DetailWidgetContent(props: { selectedItem: any; dataType: IDataType; detailWidget: IDetailWidget }) {
  const { selectedItem, dataType, detailWidget } = props;

  const { clientId } = useClientContext();

  const variables = {
    clientId,
    item: selectedItem,
    dataTypeSlug: dataType.id,
    detailWidgetSlug: detailWidget.id,
  };

  const { data, loading } = useQuery(QUERY_DETAIL_WIDGET, { variables });

  const widgetData = data?.dataTypeDetailWidget?.data?.data ?? data?.dataTypeDetailWidget?.data;
  const configuration = data?.dataTypeDetailWidget?.configuration;

  const Component = WIDGET_COMPONENTS_MAP[detailWidget.type];

  if (!Component || !clientId) {
    return null;
  }

  return (
    <WidgetContents
      clientId={clientId}
      component={Component}
      data={widgetData}
      configuration={configuration}
      loading={loading}
      // widgetDefinition={detailWidget}
    />
  );
}

function DetailWidgetLi(props: {
  detailWidget: IDetailWidget;
  dataType: IDataType;
  selectedItem?: any; // if selected, widgets pass this to backend to get widget data.
}) {
  const { detailWidget, dataType, selectedItem } = props;

  // ========== Query widget data ==========

  // ========== Generic dget Component ==========

  return (
    <DetailWidgetLiRoot>
      <NuCard>
        <NuCardTitle title={detailWidget.name} />

        <NuCardContent>
          {!selectedItem && (
            <Empty
              image={
                <AreaChartOutlined
                  style={{
                    fontSize: '100px',
                    color: baseTheme.color.gray2,
                  }}
                />
              }
              style={{ color: baseTheme.color.gray5, marginBottom: '16px' }}
              description="Select Item"
            />
          )}
          {Boolean(selectedItem) && (
            <DetailWidgetContent dataType={dataType} selectedItem={selectedItem} detailWidget={detailWidget} />
          )}
        </NuCardContent>
      </NuCard>
    </DetailWidgetLiRoot>
  );
}

const DwlRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

function DetailWidgetsList(props: {
  dataType: IDataType;
  selectedItem?: any; // if selected, widgets pass this to backend to get widget data.
}) {
  const {
    dataType,
    dataType: { detailWidgets },
    selectedItem,
  } = props;

  if (!detailWidgets.length) {
    return <EmptyState>This Data Type does not have any detail widgets.</EmptyState>;
  }

  return (
    <DwlRoot>
      {detailWidgets.map((dw) => (
        <DetailWidgetLi key={dw.id} detailWidget={dw} selectedItem={selectedItem} dataType={dataType} />
      ))}
    </DwlRoot>
  );
}
const DetailWidgetRoot = styled.div`
  padding: 18px;
`;

const SUMMARY_WIDGET_DEFINITION_DATA = gql`
  query SummaryWidgetDefinitionData(
    $clientId: String!
    $dataTypeKey: String
    $debugRequestId: String
    $filters: [QueryDataTypeFilter!]
    $settings: JSONObject
    $slug: String!
    $time: String
    $variables: JSONObject
    $viewingClientId: String
  ) {
    widgetDefinition(slug: $slug, dataTypeKey: $dataTypeKey) {
      id
      slug
      type
      data(
        clientId: $clientId
        debugRequestId: $debugRequestId
        filters: $filters
        settings: $settings
        time: $time
        variables: $variables
        viewingClientId: $viewingClientId
      )
      configuration(settings: $settings)
      name
      description
      canDebug
    }
  }
`;

export type SummaryWidgetLiProps = {
  dataTypeKey: string;
  name: string;
  widgetType: string;
  widgetSlug: string;
  searchParams: { [key: string]: string };
  time?: string;
};

type WidgetOptionProps = SummaryWidgetLiProps & {
  canDebug: boolean;
  clientId: string | undefined;
  debugRequestId?: string;
  filters: QueryDataTypeFilter[];
  refetch: () => void;
  subAction?: any;
};

export const CREATE_WIDGET = gql`
  mutation CreateWidget($input: CreateWidgetInput!) {
    createWidget(input: $input) {
      id
    }
  }
`;

function AddToDashboardsModal(
  props: WidgetOptionProps & {
    visible: boolean;
    onDone: () => void;
  },
) {
  const { visible, clientId, name, widgetSlug, searchParams: settings, filters } = props;

  const [widgetName, setWidgetName] = useState<string>(name);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const handleRowSelection = (selectedKeys, _selectedRows) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    onChange: handleRowSelection,
    selectedRowKeys,
  };

  // // Mutations
  const [createWidget, { loading }] = useMutation(CREATE_WIDGET);

  const handleSave = async () => {
    const dashboardIds = selectedRowKeys;
    if (!dashboardIds?.length) {
      message.error('Please select at least 1 dashboard to add widget to.');
      return;
    }

    // save widget.
    try {
      const input = {
        clientId,
        name: widgetName, // todo: allow them to rename the widget at time of creation.
        widgetSlug: widgetSlug,
        settings,
        filters,
        dashboardIds,
      };

      const createResult = await createWidget({ variables: { input } });

      message.success(
        `Saved Widget and added to ${dashboardIds.length} dashboard${dashboardIds.length > 1 ? 's' : ''}`,
      );

      // "reset form"
      setSelectedRowKeys([]);
      props.onDone();
    } catch (err: any) {
      console.error(err);

      message.error(`Failed to create widget and add to dashboards.`);
    }
  };

  if (!visible) {
    return null;
  }

  const isValid = selectedRowKeys?.length && widgetName?.length;

  return (
    <Modal width={800} open={visible} title={'Add Widget to Dashboard(s)'} onCancel={props.onDone} footer={false}>
      <Form layout="vertical">
        <Form.Item label="Widget Name" rules={[{ required: true }, { message: 'Widget Name is Required' }]}>
          <Input
            value={widgetName}
            onChange={(e) => {
              const value = e.target.value;

              setWidgetName(value);
            }}
          />
        </Form.Item>
        <Form.Item label="Select Dashboards">
          <DashboardsList onClose={props.onDone} rowSelection={rowSelection} />
        </Form.Item>
      </Form>

      <div>
        <Space direction="horizontal">
          <NuButton loading={loading} disabled={!isValid} onClick={handleSave} type="primary">
            Add
          </NuButton>
          <NuButton onClick={props.onDone}>Cancel</NuButton>
        </Space>
      </div>
    </Modal>
  );
}

function WidgetOptions(props: WidgetOptionProps) {
  const debugCtx = useDebugRequests();

  const [dashboardModalOpen, setDashboardModal] = useState<boolean>(false);

  const handleAddToDashboardClick = () => {
    setDashboardModal(true); // opens modal.
  };

  const onRefetchClick = (): void => {
    props.refetch();
  };

  const items: MenuProps['items'] = [
    {
      icon: <PlusCircleOutlined />,
      key: 'add-to-dashboard',
      label: 'Add to Dashboard(s)',
      onClick: handleAddToDashboardClick,
    },
    {
      icon: <SyncOutlined />,
      key: 'refetch',
      label: 'Refresh',
      onClick: onRefetchClick,
    },
  ];

  if (props.canDebug && props.debugRequestId) {
    const isDebugging: boolean = debugCtx.hasRequestId(props.debugRequestId);
    const onDebugClick = (): void => {
      if (isDebugging) {
        debugCtx.removeRequestId(props.debugRequestId!);
        return;
      }

      debugCtx.addRequestId(props.debugRequestId!);
    };

    items.push({
      icon: isDebugging ? <CloseOutlined /> : <CloudServerOutlined />,
      key: 'debug-requests',
      label: isDebugging ? 'Remove debug' : 'Debug widget',
      onClick: onDebugClick,
    });
  }

  return (
    <>
      {props?.subAction && props.subAction}
      <Dropdown trigger={['click']} placement="bottomRight" menu={{ items }}>
        <NuButton shape="circle" icon={<NuIcons.Ellipsis style={{ fontSize: '24px' }} />} type="text" />
      </Dropdown>
      {dashboardModalOpen && (
        <AddToDashboardsModal {...props} visible={dashboardModalOpen} onDone={() => setDashboardModal(false)} />
      )}
    </>
  );
}

function SummaryWidgetLi(props: {
  dataTypeKey: string;
  name: string;
  widgetType: string;
  widgetSlug: string;
  dataExplorerFilters: DataExplorerFilters;
}) {
  const { dataTypeKey, name, widgetType, widgetSlug, dataExplorerFilters } = props;

  const { user } = useAuthContext();
  const debugCtx = useDebugRequests();
  const [subAction, setSubAction] = useState(null);
  const { clientId } = useClientContext();

  const debugRequestId = useMemo(() => {
    if (!debugCtx.canDebugRequests) {
      return undefined;
    }

    return createDebugRequestId({ suffix: `data-explorer:summary-widget:${props.widgetSlug}`, user });
  }, [debugCtx.canDebugRequests, props.widgetSlug, user]);

  const {
    dataExplorerFilters: {
      queryFilters,
      time,
      searchParamsApi: { parsed: searchParams },
    },
  } = props;

  const { data, loading, refetch } = useQuery<
    SummaryWidgetDefinitionDataQuery,
    SummaryWidgetDefinitionDataQueryVariables
  >(SUMMARY_WIDGET_DEFINITION_DATA, {
    variables: {
      clientId: clientId ?? '',
      dataTypeKey,
      debugRequestId: debugCtx.hasRequestId(debugRequestId) ? debugRequestId : undefined,
      filters: dataExplorerFilters.queryFilters,
      settings: searchParams,
      slug: widgetSlug,
      time,
      viewingClientId: clientId,
    },
    skip: !clientId,
  });
  const widgetData = data?.widgetDefinition?.data;
  const configuration = data?.widgetDefinition?.configuration;

  // get React component
  const Component = WIDGET_COMPONENTS_MAP[widgetType];
  if (!Component || !clientId) {
    return null;
  }

  return (
    <DetailWidgetLiRoot>
      <NuCard>
        <NuCardTitle
          title={name}
          actions={
            <WidgetOptions
              {...props}
              canDebug={data?.widgetDefinition?.canDebug ?? false}
              clientId={clientId}
              debugRequestId={debugRequestId}
              filters={queryFilters}
              refetch={refetch}
              searchParams={searchParams}
              subAction={subAction}
            />
          }
        />

        <NuCardContent>
          <WidgetContents
            clientId={clientId}
            dataTypeKey={dataTypeKey}
            component={Component}
            data={widgetData}
            configuration={configuration}
            loading={loading}
            title={name}
            setSubAction={setSubAction}
          />
        </NuCardContent>
      </NuCard>
    </DetailWidgetLiRoot>
  );
}

export function SummaryWidgets(props: { dataType: IDataType; dataExplorerFilters: DataExplorerFilters }) {
  const {
    dataType: { id: dataTypeKey, summaryWidgets },
    dataExplorerFilters,
  } = props;

  if (!summaryWidgets?.length) {
    return <EmptyState>This Data Type does not have any Summary Widgets.</EmptyState>;
  }

  return (
    <DwlRoot>
      {summaryWidgets.map(({ id, name, type }) => (
        <SummaryWidgetLi
          key={id}
          dataTypeKey={dataTypeKey}
          name={name}
          widgetType={type}
          widgetSlug={id}
          dataExplorerFilters={dataExplorerFilters}
        />
      ))}
    </DwlRoot>
  );
}

/**
 * Display Detail Widgets when a row in DataExplorer has been selected.
 *
 */

export function DataTypeWidgets(props: {
  dataType: IDataType;
  selectedItem?: any; // if selected, widgets pass this to backend to get widget data.

  // class that manages search params and parsing.
  dataExplorerFilters: DataExplorerFilters;
}) {
  const { selectedItem, dataType, dataExplorerFilters } = props;

  if (!selectedItem) {
    return (
      <DetailWidgetRoot>
        <SummaryWidgets dataType={dataType} dataExplorerFilters={dataExplorerFilters} />
      </DetailWidgetRoot>
    );
  }

  return (
    <DetailWidgetRoot>
      <DetailWidgetsList {...props} />
    </DetailWidgetRoot>
  );
}
